import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import * as countries from 'i18n-iso-countries';
import enCountryData from 'i18n-iso-countries/langs/en.json';
import nlCountryData from 'i18n-iso-countries/langs/nl.json';
import { GridOptions } from 'ag-grid-community';
import api from '@/helpers/api';
import { supportedLanguages } from '@cutr/constants/i18n';

const NAMESPACES = [
  'common',
  'cutlist-admin',
  'home',
  'files',
  'order-files',
  'order',
  'order-todo',
  'order-delivery',
  'order-checklist',
  'order-materials',
  'order-project',
  'billing',
  'order-quote',
  'costdoc',
  'rfq',
  'customer',
  'navigation',
  'auth',
  'settings',
  'address',
  'business',
  'workspace',
  'formulate',
  'address-facilities',
  'materials',
  'invoices',
  'ag-grid',
  'quote-estimate',
  'landing-page',
  'dxf-conversion',
  'accept-pricing',
  'cutlist',
  'messages',
  'notifications',
  'order-checkout',
  'cabinator',
  'hardware',
  // pricing-document is reserved for server-side translations
];

const isDev = import.meta.env.DEV && !import.meta.env.VITEST;
// Register each supported language:
countries.registerLocale(enCountryData);
countries.registerLocale(nlCountryData);

const getSupportedLanguages = () => {
  if (['development', 'staging'].includes(import.meta.env.VITE_CUTR_ENV)) {
    // return FR when development in staging to allow testing French without rolling it out to production (CTR-9235)
    return ['en', 'nl', 'fr', 'dev'];
  }
  return supportedLanguages;
};

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init(
    // When making changes, make sure to update types/i18next.d.ts as well!
    {
      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },
      returnEmptyString: false,
      load: 'languageOnly',
      ns: NAMESPACES,
      debug: false,
      fallbackLng: ['en', 'dev'],
      supportedLngs: getSupportedLanguages(),
      appendNamespaceToCIMode: true,
      returnNull: false,
    }
  )
  .then((value) => {
    // i18next does not set resolvedLanguage if language is `dev` because `en` translations are not available. This should only happen in development mode.
    if (isDev && !i18next.resolvedLanguage) {
      i18next.resolvedLanguage = 'en';
    }
    importServerTranslations();
    return value;
  });

export default i18next;

export const AgGridGetLocaleText: GridOptions['getLocaleText'] = (params) =>
  i18next.t(`ag-grid:${params.key}`);

async function importServerTranslations() {
  return api.get('/v2/pricing-document/locales/all').then(({ data }) => {
    Object.keys(data).forEach((lng) => {
      i18next.addResourceBundle(lng, 'pricing-document', data[lng]);
    });
  });
}

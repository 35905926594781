import Debug from 'debug';
const debug = Debug('tracker:consent-manager');

const DEFAULT_PREFERENCES = {
  advertising: true,
  marketingAndAnalytics: true,
  functional: true,
};
const CONTAINER_SELECTOR = '#consent-manager-container';
function preferencesToGtagConsentConfig(customPreferences) {
  const { advertising, marketingAndAnalytics, functional } = customPreferences;
  return {
    ad_user_data: marketingAndAnalytics ? 'granted' : 'denied',
    ad_personalization: marketingAndAnalytics ? 'granted' : 'denied',
    ad_storage: advertising ? 'granted' : 'denied',
    analytics_storage: marketingAndAnalytics ? 'granted' : 'denied',
    functionality_storage: functional ? 'granted' : 'denied',
    personalization_storage: functional ? 'granted' : 'denied',
  };
}

export function initializeConsentManager() {
  let consentManager;

  // Define dataLayer and the gtag function.
  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  window.consentManagerConfig = (exports) => {
    consentManager = exports;

    window.analytics.ready(() => {
      const preferences =
        consentManager.preferences.loadPreferences()?.customPreferences;
      gtag(
        'consent',
        'default',
        preferencesToGtagConsentConfig(preferences || DEFAULT_PREFERENCES)
      );
    });

    consentManager.preferences.onPreferencesSaved((config) => {
      gtag(
        'consent',
        'update',
        preferencesToGtagConsentConfig(config.customPreferences)
      );
    });
    return consentManagerConfig(exports);
  };

  return {
    openConsentManager: () => consentManager.openConsentManager(),
    implyConsent: () => {
      // ensure any missing elements due to blockers don't cause app errors
      try {
        const hasPreferences = Boolean(
          Object.keys(consentManager.preferences.loadPreferences()).length
        );
        if (hasPreferences) return;
        document
          .querySelector(`${CONTAINER_SELECTOR} button[title="Close"]`)
          .click();
      } catch (e) {
        debug('Error: could not imply consent', e);
      }
    },
  };
}

function consentManagerConfig(exports) {
  const React = exports.React;

  const bannerContent = React.createElement(
    'span',
    null,
    'Cutr uses cookies that are needed to operate our services. By closing this banner or continuing to browse this site, you’re agreeing to our ',
    React.createElement(
      'a',
      {
        href: 'https://www.cutr.com/website-data-collection-policy',
        target: '_blank',
      },
      'Website Data Collection Policy'
    ),
    '. You can customize your preferences in your Data Preferences.'
  );
  const bannerSubContent = 'Set preferences';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent = React.createElement(
    'div',
    null,
    React.createElement(
      'p',
      null,
      'Cutr uses data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.'
    ),
    React.createElement(
      'p',
      null,
      'By using our website, you\u2019re agreeing to our',
      ' ',
      React.createElement(
        'a',
        {
          href: 'https://www.cutr.com/website-data-collection-policy',
          target: '_blank',
        },
        'Website Data Collection Policy'
      ),
      '.'
    ),
    React.createElement(
      'p',
      null,
      'The table below outlines how we use this data by category. To opt out of a category of data collection, select \u201CNo\u201D and save your preferences.'
    )
  );
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent = React.createElement(
    'div',
    null,
    'Your preferences have not been saved. By continuing to use our website, you\u2019re agreeing to our',
    ' ',
    React.createElement(
      'a',
      {
        href: 'https://www.cutr.com/website-data-collection-policy',
        target: '_blank',
      },
      'Website Data Collection Policy'
    ),
    '.'
  );
  return {
    container: CONTAINER_SELECTOR,
    writeKey: import.meta.env.VITE_SEGMENT_WRITE_KEY,
    bannerContent: bannerContent,
    bannerSubContent: bannerSubContent,
    preferencesDialogTitle: preferencesDialogTitle,
    preferencesDialogContent: preferencesDialogContent,
    defaultDestinationBehavior: 'imply',
    cancelDialogTitle: cancelDialogTitle,
    cancelDialogContent: cancelDialogContent,
    closeBehavior: () => DEFAULT_PREFERENCES,
    shouldRequireConsent: exports.inEU,
  };
}

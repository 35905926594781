import { bankersRound } from './math.js';
import i18n from './i18n';

export const format = {
  boolean(value) {
    if (value === undefined || value === null) return '';

    return i18n.t(`common:boolean.${value ? 'yes' : 'no'}`);
  },
  date(date, dateStyle = 'short', timeStyle, options = {}) {
    if (!date) return '';
    if (dateStyle === '') dateStyle = undefined;
    const hasTime = date instanceof Date || date.includes('T');
    return new Intl.DateTimeFormat(getFormattingLocale(), {
      dateStyle,
      timeStyle,
      timeZone: hasTime ? undefined : 'UTC',
      ...options,
    }).format(new Date(date));
  },

  weekNumber(date) {
    if (!date) return '';
    const d = new Date(date);
    const oneJan = new Date(d.getFullYear(), 0, 1);
    const numberOfDays = Math.floor((d - oneJan) / (24 * 60 * 60 * 1000));
    return Math.ceil((d.getDay() + 1 + numberOfDays) / 7);
  },

  dateTime(date, options) {
    if (!date) return '';
    return new Intl.DateTimeFormat(getFormattingLocale(), options).format(
      new Date(date)
    );
  },

  time(time, timeStyle = 'short') {
    if (!time) return '';
    const isoDateString = new Date().toISOString().split('T')[0];

    return new Intl.DateTimeFormat(getFormattingLocale(), {
      timeStyle,
    }).format(new Date(`${isoDateString}T${time}`));
  },

  /**
   * Formats a date relative to another date or the current time.
   * @param {Date|string} sourceDate - The date to format.
   * @param {Object} options - The options for formatting.
   * @param {Date|string} [options.targetDate] - The date to compare against. Defaults to current time if not provided.
   * @param {string} [options.style='long'] - The formatting style ('long', 'short', or 'narrow').
   * @returns {string} The formatted relative time string.
   */
  relativeTime(sourceDate, { targetDate, style = 'long' } = {}) {
    if (!sourceDate) return '';
    const locale = i18n.resolvedLanguage || 'en';
    // en-GB doesn't support narrow style
    const adjustedLocale = locale.includes('en') ? 'en-US' : locale;

    const formatter = new Intl.RelativeTimeFormat(adjustedLocale, {
      style,
    });

    const WEEK_IN_MS = 6.048e8,
      DAY_IN_MS = 8.64e7,
      HOUR_IN_MS = 3.6e6,
      MIN_IN_MS = 6e4,
      SEC_IN_MS = 1e3;

    const inputMS =
      typeof sourceDate === 'string'
        ? getUTCTime(new Date(sourceDate))
        : getUTCTime(sourceDate);
    const diffMS =
      inputMS - getUTCTime(targetDate ? new Date(targetDate) : new Date());

    const TWO_WEEKS = 2 * WEEK_IN_MS;
    if (Math.abs(diffMS) > TWO_WEEKS) {
      return formatter.format(Math.trunc(diffMS / WEEK_IN_MS), 'week');
    }

    if (Math.abs(diffMS) > DAY_IN_MS) {
      return formatter.format(Math.trunc(diffMS / DAY_IN_MS), 'day');
    }
    if (Math.abs(diffMS) > HOUR_IN_MS) {
      return formatter.format(
        Math.trunc((diffMS % DAY_IN_MS) / HOUR_IN_MS),
        'hour'
      );
    }
    if (Math.abs(diffMS) > MIN_IN_MS) {
      return formatter.format(
        Math.trunc((diffMS % HOUR_IN_MS) / MIN_IN_MS),
        'minute'
      );
    }
    return formatter.format(
      Math.trunc((diffMS % MIN_IN_MS) / SEC_IN_MS),
      'second'
    );
  },

  currency(amount, currency, options = {}) {
    const formatterOptions = {};
    if (currency === false) {
      formatterOptions.minimumFractionDigits = 2;
    } else {
      formatterOptions.style = 'currency';
      formatterOptions.currency = currency || 'eur';
    }
    return new Intl.NumberFormat(getFormattingLocale(), {
      ...formatterOptions,
      ...options,
    }).format(bankersRound(amount) / 100);
  },

  unit(value, options = {}) {
    if (value === undefined || value === null) return '';
    // options.unit options: https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers
    return new Intl.NumberFormat(getFormattingLocale(), {
      style: 'unit',
      ...options,
    }).format(value);
  },

  capitalize(string = '') {
    return string
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : '';
  },
};

export default {
  install(Vue) {
    Vue.$format = format;
    Vue.prototype.$format = format;

    Object.keys(format).map((formatter) => {
      Vue.filter(formatter, (...args) => format[formatter](...args));
    });
  },
};

function getUTCTime(date) {
  return date.getTime() - date.getTimezoneOffset() * 60000;
}

export function getFormattingLocale() {
  const lang = i18n.resolvedLanguage || 'en';
  return lang === 'en' ? 'en-GB' : lang;
}

<template>
  <div>
    <header class="navbar">
      <div class="menu-section">
        <a
          class="menu-item menu-home"
          :href="!isSignedIn ? 'https://cutr.com' : '/home'"
        >
          <div class="flex-hor-start">
            <CutrLogo logoFill="#FFBE2E" textFill="#101828" />
          </div>
        </a>
      </div>

      <template v-if="!$route.meta?.whiteLabel">
        <div
          class="menu-section"
          v-if="
            store.canSwitch &&
            ['Customer', 'Maker'].includes(store.activeSessionType)
          "
        >
          <div class="menu-item">
            <Badge :class="store.activeSessionType" variant="pill">{{
              $t(`auth:orgTypes.${store.activeSessionType.toLowerCase()}`)
            }}</Badge>
          </div>
        </div>
        <div class="menu-section flexAlign" style="gap: var(--navItemGap)">
          <router-link
            v-for="(link, index) in navLinksForUser"
            :key="index"
            :to="{ name: link.name }"
            class="menu-item"
          >
            {{ $t(`navigation:menu.${link.title}`) }}
          </router-link>
        </div>
        <div class="menu-section" v-if="store.isAdmin">
          <div
            class="menu-item"
            :style="
              isAdministrationPage
                ? 'box-shadow: inset 0px -2px 0px 0px var(--gray-9)'
                : ''
            "
          >
            <VMenu class="admin-menu">
              <a href="#" @click.prevent style="line-height: 2"
                >{{ 'Administration' }} <span data-i="arrow_drop_down"
              /></a>
              <template #popper="{ hide }">
                <menu class="btn-menu">
                  <li v-for="(link, index) in administrationLinks" :key="index">
                    <Button
                      variant="menu"
                      :to="{ name: link.name }"
                      @click="hide"
                      :class="{ active: link.name === route.name }"
                    >
                      {{ $t(`navigation:menu.${link.title}`) }}
                    </Button>
                  </li>
                </menu>
              </template>
            </VMenu>
          </div>
        </div>

        <div class="menu-section">
          <div class="menu-item">
            <template v-if="!isSignedIn">
              <Button
                class="white compact"
                v-show="$route.name !== 'signin'"
                @click="goToRoute('signin')"
              >
                {{ $t('navigation:menu.signIn') }}
              </Button>
              <Button
                class="white compact"
                v-show="$route.name === 'signin'"
                @click="goToRoute('signup')"
              >
                {{ $t('navigation:menu.signUp') }}
              </Button>
            </template>
            <MessagesBubble style="margin-right: 0.5rem" v-if="isSignedIn" />
            <DropdownMenu
              v-if="isSignedIn"
              :buttonsList="accountMenuLinks"
              triggerColor="white"
              triggerIcon="person"
              triggerClass="compact"
              triggerOnHover
              triggerDataCy="account-menu"
            />
          </div>
        </div>

        <div class="menu-section lng" v-show="!isSignedIn">
          <LangSelector class="menu-item" />
        </div>

        <div class="menu-section mobileMenu">
          <div class="menu-item">
            <template v-if="!isSignedIn">
              <Button
                class="white compact"
                v-show="$route.name !== 'signin'"
                @click="goToRoute('signin')"
              >
                {{ $t('navigation:menu.signIn') }}
              </Button>
              <Button
                class="white compact"
                v-show="$route.name === 'signin'"
                @click="goToRoute('signup')"
              >
                {{ $t('navigation:menu.signUp') }}
              </Button>
            </template>
            <Button
              color="borderless"
              class="compact"
              :icon="isOpen ? 'close' : 'menu'"
              @click="isOpen = !isOpen"
            />
          </div>
        </div>
      </template>
      <div v-if="$route.meta?.whiteLabel" style="margin-left: auto" />
    </header>

    <MobileMenu v-if="isOpen" :onClose="() => (isOpen = false)" />
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '@/composables/plugins';
import { useActiveUserStore } from '@/modules/store';
import { computed, ref, watchEffect } from 'vue';

import CutrLogo from '@/components/common/CutrLogo.vue';
import LangSelector from '@/elements/Navigation/LangSelector.vue';
import MobileMenu from '@/elements/Navigation/MobileMenu.vue';
import { useNavigation } from '@/elements/Navigation/composables';
import MessagesBubble from '@/elements/Notifications/MessagesBubble.vue';
import DropdownMenu from '../common/DropdownMenu.vue';

const store = useActiveUserStore();
const router = useRouter();
const route = useRoute();
const isOpen = ref(false);

const { navLinks, accountMenuLinks, isSignedIn, goToRoute } = useNavigation();

watchEffect(() => {
  document.body.style.overflow = isOpen.value ? 'hidden' : 'auto';
});

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) isOpen.value = false;
  next();
});

const administrationLinks = navLinks.value.filter(
  ({ type }) => type === 'administration'
);
const navLinksForUser = computed(() =>
  navLinks.value.filter((n) => !(n.type === 'administration'))
);

const isAdministrationPage = computed(() =>
  administrationLinks.some((link) => link.name === route.name)
);
</script>

<style scoped>
.admin-menu {
  [data-i]:before {
    margin: 0;
  }
}
</style>
